<template>
  <div class="main_content">
    <div class="title_box">
      <span></span>
      <h5>处方管理</h5>
    </div>
    <div class="line"></div>
    <div class="operate_box">
      <div class="search_box">
        <div class="input_box">
          <span style="width: 100px">处方名称：</span>
          <el-input placeholder="请输入" v-model="searchForm.prescriptionName"></el-input>
        </div>
        <div class="input_box">
          <span>病种：</span>
          <el-select v-model="searchForm.applyDiseaseid" clearable placeholder="请选择">
            <el-option v-for="item in diseaseList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="input_box">
          <span style="width:70px">创建人：</span>
          <el-input placeholder="请输入" v-model="searchForm.createUser"></el-input>
        </div>
        <div class="input_box"><el-button type="primary" class="el-icon-search" @click="searchBtn">搜索</el-button></div>
      </div>

      <div class="operate_btn">
        <el-button type="warning" icon="el-icon-plus" @click="addReciple">新增</el-button>
      </div>
    </div>
    <div class="tbale_box">
      <el-table :data="tableData" border style="width: 100%" height="100%">
        <el-table-column fixed prop="id" label="编号" width="140" />
        <el-table-column prop="prescriptionName" width="160" label="处方名称" />
        <el-table-column prop="explain" label="处方规则" />
        <el-table-column prop="createUser" width="120" label="创建人" />
        <el-table-column prop="createTime" label="创建时间" width="140" />
        <el-table-column label="操作" width="140">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="编辑" placement="top">
              <el-button @click="updateTask(scope.row)" type="primary" icon="el-icon-edit" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="查看" placement="top">
              <el-button @click="updateTask(scope.row)" type="warning" icon="el-icon-document" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top">
              <el-button @click="updateTask(scope.row)" type="danger" icon="el-icon-delete" circle></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <new-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size=pageBox.pageSize
      layout="total,sizes, prev, pager, next" :total=pageBox.total>
    </new-pagination>
    <!--查看详情-->
    <el-dialog :visible.sync="editDialog" width="40%" :close-on-click-modal="false" :close-on-press-escape="false"
      class="set_dialog_style">
      <div slot="title" class="dialog_title">
        <span></span>{{ operateBox.title }}
      </div>
      <edit-from :key="timer" @closed="closed"></edit-from>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api/index'
import EditFrom from "./components/edit-from";
import { diseaseData } from '@/utils/baseData'
export default {
  name: 'index',
  components: {
    EditFrom
  },
  data() {
    return {
      tableData: [],
      editDialog: false,
      operateBox: {
        title: '新增处方',
        operate: 'add',
        id: null
      },
      searchForm: {
        prescriptionName: '',
        applyDiseaseid: null,
        createBy: ''
      },
      diseaseList: diseaseData,
      pageBox: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      timer: null,
    }
  },
  created() {
    this.getList()
  },
  methods: {

    async getList() {
      try {
        let res = await api.getRecipelList(this.searchForm, this.pageBox)
        if (res.code == 200) {
          console.log(res)
          this.tableData = res.rows
          this.pageBox.total = res.total
        }
      } catch (e) {

      }
    },
    /* 一页显示多少条切换 */
    handleSizeChange(val) {
      this.pageBox.pageSize = val
      this.getList()
    },
    /* 分页切换 */
    handleCurrentChange(val) {
      this.pageBox.pageNum = val
      this.getList()
    },
    //条件查询
    searchBtn() {
      this.getList()
    },
    // 删出积分记录
    delScore(row) {

    },

    //新增
    addReciple() {
      this.timer = new Date().getTime()
      this.editDialog = true
    },

    closed() {
      this.editDialog = false
    }
  },
  watch: {}
}
</script>

<style scoped lang="less">
.input_box {
  width: 240px;

  .el-input {
    width: 70%;
  }

  .el-select {
    width: 70%;
  }
}
</style>
