<template>
  <div class="form_box">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
      <el-form-item label="处方名称:" prop="userId">
        <el-input v-model="ruleForm.score"></el-input>
      </el-form-item>
      <div>
        <el-form-item label="适应病种:" prop="type">
          <el-select v-model="ruleForm.type" clearable>
            <el-option v-for="item in taskTypes" :label="item.label" :value="item.value">{{ item.label }}</el-option>
          </el-select>
        </el-form-item>
      </div>
      <el-form-item label="适应健康情况:" prop="score">
        <div class="health_condition_box" v-for="item in healthConditionList" :key="item.id">
          <el-checkbox v-model="item.value">{{ item.label }}</el-checkbox><el-input placeholder="输入下限"
            v-model="item.lowerLimitL"></el-input><el-input placeholder="输入上限" v-model="item.upperlimit"></el-input>
        </div>
      </el-form-item>
      <el-form-item label="处方内容:" prop="content">
        <el-input v-model="ruleForm.content" type="textarea"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button @click="cancelFrom">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { chinaArea } from '@/utils/china-area-data'
import { taskTypeList } from '@/utils/baseData'
import api from '@/api/index'
import debounce from 'lodash/debounce';
export default {
  data() {
    return {
      ruleForm: {
        userId: '',
        type: '',
        score: '',
        livingId: ''
      },
      taskTypes: taskTypeList,
      personList: [],
      rules: {
        userId: [
          { required: true, message: '用户不能为空', trigger: 'blur' },
        ],
        type: [
          { required: true, message: '积分类型不能为空', trigger: 'blur' },
        ],
        score: [
          { required: true, message: '积分不能为空', trigger: 'blur' },
        ]
      },
      healthConditionList: [
        { id: 1, value: '', upperlimit: '', lowerLimitL: '', label: 'BMI' },
        { id: 2, value: '', upperlimit: '', lowerLimitL: '', label: '腰围' },
        { id: 3, value: '', upperlimit: '', lowerLimitL: '', label: '血压' },
        { id: 4, value: '', upperlimit: '', lowerLimitL: '', label: '血糖' },
        { id: 5, value: '', upperlimit: '', lowerLimitL: '', label: '血脂' }
      ]
    };
  },
  created() {
    this.getResidents()
  },
  methods: {

    //获取居民列表
    async getResidents() {
      let data = {}
      let page = {
        pageSize: 999999,
        pageNum: 1,
      }
      try {
        let res = await api.getResidentList(data, page)
        if (res.code == 200) {
          this.personList = res.rows
        }
      } catch (e) {
        debugger
        this.$message.error(e.msg)
      }
    },
    submitForm: debounce(function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = this.ruleForm
          this.scoreAdd(data)
        } else {
          return false;
        }
      });
    }, 500),
      //新增积分
      async scoreAdd(p){
      try {
        let res = await api.addScore(p)
          if(res.code == 200){
  this.$message.success('新增成功！')
  this.$emit('closed')
}else {
  this.$message.warning(res.mag)
}
        }catch (e) {
  this.$message.error(e.msg)
}
      },
cancelFrom(formName) {
  this.$emit('closed')
}
    }
  }
</script>

<style scoped lang="less">
.el-select {
  width: 100%;
}

.el-cascader {
  width: 100%
}

.health_condition_box {
  display: flex;
  margin-bottom: 10px;

  .el-input {
    width: 120px;
    margin-left: 10px;
  }
}
</style>
